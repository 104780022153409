<template>
  <section class="invoice-add-wrapper">
    <!-- Alert: No item found -->

    <b-row class="invoice-add">
      <!-- Col: Left (Invoice Container) -->
      <b-col cols="12">
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-card
              no-body
              class="invoice-preview-card"
            >
            
              <!-- Header -->
              <b-card-body>
               <b-row>


                <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  المانح"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.donor_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="donorOption"
                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  biller"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.biller_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="billerOption"
                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  output"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.out_put_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="outPutOption"
                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >

                        <b-form-group
                          label="  out_come_id"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                          <v-select
                            v-model="Form.out_come_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="outComeOption"
                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  الحساب"
                          label-for="payment-method"
                          :state="errors.length > 0 ? false : null"
                        >
                       
                          <v-select
                            v-model="Form.account_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="accountList"
                            label="name"
                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                            @input="getSubAccount(Form.account_id)"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                  <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="  الحساب الفرعي"
                          :state="errors.length > 0 ? false : null"
                          label-for="payment-method"
                        >
                          <v-select
                            v-model="Form.sub_account_id"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="SubAccountList"
                            label="name"

                            :clearable="false"
                            :reduce="(val) => val.id"
                            class="mb-2 item-selector-title"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
                     <b-col
                    cols="12"
                    lg="3"
                  >
                    <div class="mt-2">
                      <validation-provider
                        #default="{ errors }"
                        rules="required"
                      >
                        <b-form-group
                          label="   الميزانية"
                          :state="errors.length > 0 ? false : null"
                          label-for="payment-method"
                        >
                      
                <b-form-input
                            v-model="Form.budget"
                                  type="text"
                          />
                        </b-form-group>
                        <b-form-invalid-feedback
                          :state="errors.length > 0 ? false : null"
                        >
                          {{ errors[0] }}
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </b-col>
               </b-row>
              </b-card-body>

              <!-- Spacer -->
              <hr class="invoice-spacing mx-5">

               <b-card title="بيانات " v-if="FormData">
   <b-row v-if="FormData.status_id">
    <b-col cols="2">status</b-col>
      <b-col cols="10">{{FormData.status_id.name}}</b-col>
   </b-row>
     <b-row v-if="FormData.type_id">
      <b-col cols="4">نوع العملية</b-col>
      <b-col cols="8">{{ FormData.type_id.name }}</b-col>
    </b-row>

    <b-row v-if="FormData.from_party_id">
      <b-col cols="4">الجهة المرسلة</b-col>
      <b-col cols="8">{{ FormData.from_party_id.first_name }} {{ FormData.from_party_id.last_name }}</b-col>
    </b-row>
    
    <b-row v-if="FormData.user_material_order_id">
      <b-col cols="4">طلب المستخدم</b-col>
      <b-col cols="8">{{ FormData.user_material_order_id.code }}</b-col>
    </b-row>

    <b-row v-if="FormData.request_by_user">
      <b-col cols="4">المطلوب من المستخدم</b-col>
      <b-col cols="8">{{ FormData.request_by_user }}</b-col>
    </b-row>

    <b-row v-if="FormData.donor_id">
      <b-col cols="4">Donor</b-col>
      <b-col cols="8">{{ FormData.donor_id.name }}</b-col>
    </b-row>

    <b-row v-if="FormData.biller_id">
      <b-col cols="4">Biller</b-col>
      <b-col cols="8">{{ FormData.biller_id.name }}</b-col>
    </b-row>

    <b-row v-if="FormData.out_put_id">
      <b-col cols="4">الإخراج</b-col>
      <b-col cols="8">{{ FormData.out_put_id.name }}</b-col>
    </b-row>

    <b-row v-if="FormData.out_come_id">
      <b-col cols="4">المخرجات</b-col>
      <b-col cols="8">{{ FormData.out_come_id.name }}</b-col>
    </b-row>

    <b-row v-if="FormData.sub_account_id">
      <b-col cols="4">الحساب الفرعي</b-col>
      <b-col cols="8">{{ FormData.sub_account_id.name }}</b-col>
    </b-row>

    <b-row v-if="FormData.department_id">
      <b-col cols="4">القسم</b-col>
      <b-col cols="8">{{ FormData.department_id }}</b-col>
    </b-row>

    <b-row v-if="FormData.cost_center">
      <b-col cols="4">مركز التكلفة</b-col>
      <b-col cols="8">{{ FormData.cost_center }}</b-col>
    </b-row>

    <b-row v-if="FormData.ppa_number">
      <b-col cols="4">رقم PPA</b-col>
      <b-col cols="8">{{ FormData.ppa_number }}</b-col>
    </b-row>

    <b-row v-if="FormData.code">
      <b-col cols="4">الكود</b-col>
      <b-col cols="8">{{ FormData.code }}</b-col>
    </b-row>

    <b-row v-if="FormData.date">
      <b-col cols="4">التاريخ</b-col>
      <b-col cols="8">{{ FormData.date }}</b-col>
    </b-row>

    <b-row v-if="FormData.notes">
      <b-col cols="4">ملاحظات</b-col>
      <b-col cols="8">{{ FormData.notes }}</b-col>
    </b-row>

    <!-- Handle details array -->
    <b-row v-if="FormData.history && FormData.history.length">
      <b-col cols="12">
        <b-card title="تفاصيل التاريخ">
          <b-list-group>
            <b-list-group-item v-for="detail in FormData.history" :key="detail.id">
              <p> الحالة الجديدة: {{ detail.new_status.name }}</p>
             
              <p>ملاحظات: {{ detail.notes }}</p>
                 <p>تم التعديل من قبل: {{ detail.changed_by_user }}</p>
                    <p>تاريخ: {{ detail.date }}</p>
              <!-- Include other details from the 'details' array as needed -->
            </b-list-group-item>
          </b-list-group>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="FormData.receive_date">
      <b-col cols="4">تاريخ الاستلام</b-col>
      <b-col cols="8">{{ FormData.receive_date }}</b-col>
    </b-row>

    <b-row v-if="FormData.receive_place">
      <b-col cols="4">مكان الاستلام</b-col>
      <b-col cols="8">{{ FormData.receive_place }}</b-col>
    </b-row>

    <b-row v-if="FormData.target">
      <b-col cols="4">الهدف</b-col>
      <b-col cols="8">{{ FormData.target }}</b-col>
    </b-row>
  </b-card>
                 <b-card-code title=" ">
          <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
       
          <b-row v-for="item in FormData.details">
<b-col cols="4">
  <b-form-group
                label-for="title"
              
              >
           
              <label> المادة</label>
              <br/>
              {{ item.item.name }}
            </b-form-group>
</b-col>
<b-col cols="4">
  <b-form-group
                label-for="title"
              
              >
           
              <label> الواحدة</label>
              <br/>
              {{ item.unit.name }}
            </b-form-group>
</b-col>
<b-col cols="2">
  <b-form-group
                label-for="title"
              
              >
           
              <label> الكمية</label>
              <br/>
              {{ item.quantity }}
            </b-form-group>
</b-col>
           </b-row>
        </validation-observer>
        </b-card-code>
              <b-row class="my-5" v-if="FormData.finican_manager_signature == '' ">
              <b-col
                
                md="4"
                xl="4"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  type="submit"
                >
                  <span class="align-middle"> موافق</span>
                </b-button>
              
              </b-col>
               <b-col md="4" xl="3" class="mx-5">
              <b-button variant="danger" @click="cancel">
             
رفض              </b-button>
            </b-col>
              </b-row>
            <!-- Note -->
            </b-card>
          </b-form>
        </validation-observer>
      </b-col>
    
      <!-- Right Col: Card -->
    </b-row>
     
    <!-- <invoice-sidebar-send-invoice />
        <invoice-sidebar-add-payment /> -->
  </section>
</template>

<script>
import Logo from '@core/layouts/components/Logo.vue'
import router from "@/router";
import { ref, onUnmounted } from '@vue/composition-api'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import Vue from 'vue'
import {
  BRow,
  BCol,
  BCard,

  BCardBody,
  BButton,

  BForm,
  BFormGroup,
  BFormInput,

  BFormInvalidFeedback,

  VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import financeStoreModule from '../financeStoreModule.js'
import store from '@/store'
import { useRouter } from "@core/utils/utils";
export default {
  components: {
    BRow,
    BCol,
    required,
    ValidationProvider,
    ValidationObserver,
    financeStoreModule,
    BFormInvalidFeedback,
    BCard,

    BButton,

    BForm,
    BFormGroup,
    BFormInput,

    // eslint-disable-next-line vue/no-unused-components
    flatPickr,
    vSelect,

  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],

  // Reset Tr Height if data changes
  watch: {
    // eslint-disable-next-line func-names
    'invoiceData.items': function () {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
    data() {
    return {
      FormData:{}}},
  created() {
   this.getCurrency()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {

    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form ? this.$refs.form.scrollHeight : 0)
      })
    },
       getCurrency() {
      this.$http.get(`/api/v1/show-transaction/${this.$route.params.id}`).then((res) => {
       this.FormData=res.data.data
  //     console.log( this.FormData)
        this.Form=res.data.data
   
      });
    },
  },
  setup() {
    const { route } = useRouter();
   
    const FINANCE_APP_STORE_MODULE_NAME = 'app-finance'

    // Register module
    if (!store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.registerModule(FINANCE_APP_STORE_MODULE_NAME, financeStoreModule) }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FINANCE_APP_STORE_MODULE_NAME)) { store.unregisterModule(FINANCE_APP_STORE_MODULE_NAME) }
    })

    const Form = ref({
    id:'',
      donor_id: '',
      biller_id: '',
      out_put_id: '',
      out_come_id: '',
      account_id: null,
      sub_account_id: null,
    
    })
    const invoiceData = ref(null)
    const accountList = ref([])
    const SubAccountList = ref([])

 
    store.dispatch('app-finance/GetItem').then(response => {
      //console.log(response)
      ItemData.value = response
    })
  
    const donorOption = ref([])
    const billerOption = ref([])
    store.dispatch('app-finance/GetDonor').then(response => {
      //console.log(response)
      donorOption.value = response
    })
    store.dispatch('app-finance/GetBiller').then(response => {
      //console.log(response)
      billerOption.value = response
    })
    const outComeOption = ref([])
    const outPutOption = ref([])
    store.dispatch('app-finance/GetOutCome').then(response => {
      //console.log(response)
      outComeOption.value = response
    })
    store.dispatch('app-finance/GetoutPut').then(response => {
      //console.log(response)
      outPutOption.value = response
    })
    store.dispatch('app-finance/GetAccount').then(response => {
      //console.log(response)
      accountList.value = response
    })
    const getSubAccount = id => {
      SubAccountList.value = []
      store.dispatch('app-finance/getSubAccount', { id }).then(response => {
        //console.log('ubitsOption,', response)

        response.forEach(el => {
          SubAccountList.value.push({
            id: el.id,
            name: el.name,
          })
        })
      })
    }
  

    const warehouseTRType = [
      {
        value: 1,
        label: 'شراء',
      },
      {
        value: 2,
        label: 'نقل',
      },
      {
        value: 3,
        label: 'مرتجع',
      },
      {
        value: 4,
        label: 'تلف',
      },
      {
        value: 5,
        label: 'توزيع',
      },
    ]
    const partType = ref('')
    const partTypeOption = [
      {
        value: 1,
        label: 'مستخدم',
      },
      {
        value: 2,
        label: 'مستودع',
      },
      {
        value: 3,
        label: 'مورد',
      },
    ]
    const { refFormObserver, getValidationState, resetForm } = formValidation(() => {})
    const onSubmit = () => {
      //console.log(Form)
      Form.value.id= route.value.params.id;
Form.value.approve= 1;
      store.dispatch('app-finance/CompletecreateTransaction', Form.value).then(response => {
       
        Form.value.donor_id = '',
        Form.value.biller_id = '',
        Form.value.out_put_id = '',
        Form.value.out_come_id = '',
        Form.value.account_id = '',
        
        Form.value.sub_account_id = '',
    
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت استكمال الطلب بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
     const cancel = () => {
      //console.log(Form)
      
let data={
  id:route.value.params.id,
  approve:0}
      store.dispatch('app-finance/CompletecreateTransaction', data).then(response => {
       
        Form.value.donor_id = '',
        Form.value.biller_id = '',
        Form.value.out_put_id = '',
        Form.value.out_come_id = '',
        Form.value.account_id = '',
   
        Form.value.sub_account_id = '',
    
        resetForm()
        Vue.swal({
          title: '',
          text: 'تمت استكمال الطلب بنجاح',
          icon: 'success',
          confirmButtonText: 'موافق',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
      })
    }
    return {
    cancel,
      Form,
      refFormObserver,
      getValidationState,
      onSubmit,
      resetForm,
    
      accountList,
      getSubAccount,
      SubAccountList,
      billerOption,
      donorOption,
      outComeOption,
      outPutOption,
    
      partType,
      partTypeOption,
      invoiceData,
 
      warehouseTRType,
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
  background-color: $product-details-bg;
}
button.btn.btn-outline-primary {
    margin: 1.375rem !important;
}
.border-Primary.mb-10.col-md-12.col-xl-4 {
    margin-bottom: 10px;
}
.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: 0.35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }
  }
}
button.btn.me-20.btn-outline-danger {
  margin-right: 17px;
}
</style>
